import React from 'react';
import { Field, Form, Formik, useFormikContext } from 'formik';

import { startFlow } from '../portalBackend';
import { encodeUrlTag } from '../encodeUrlTag';
import { useVisionplannerDetails } from '../useVisionplannerDetails';
import { loginWithIdToken } from '../authFunctions';

import { useLoader } from './LoaderContainer';
import Layout from './Layout';
import FormActions from './FormActions';
import PrimaryButton from './PrimaryButton';
import FormGroup from './FormGroup';
import Error from './Error';

const TextInput = ({ name, labelText }) => (
  <FormGroup>
    <label htmlFor={name}>{labelText}</label>
    <Field id={name} name={name} />
  </FormGroup>
)

const SubmitErrorDisplayer = () => {
  const { errors: { submitError } } = useFormikContext();
  return submitError ? <Error>{submitError}</Error> : null;
};

const OnboardingForm = ({ isSubmitting }) => (
  <Form>
    <TextInput name="companyName" labelText="Wat is de naam van uw bedrijf?" />
    <TextInput name="cocNumber" labelText="Wat is uw KVK-nummer?" />
    <TextInput name="tpNumber" labelText="Wat is het TP-nummer van uw intermediair (optioneel)?" />
    <SubmitErrorDisplayer />
    <FormActions>
      <a href="https://intermediair.abnamro.nl/pilot-jaarcijfers">Annuleren</a>
      <PrimaryButton type="submit" disabled={isSubmitting}>Volgende</PrimaryButton>
    </FormActions>
  </Form>
);

const INITIAL_VALUES = { companyName: '', cocNumber: '', tpNumber: '' };

const Onboarding = () => {
  const visionplannerDetails = useVisionplannerDetails()?.data;
  const loader = useLoader();

  const handleSubmit = async ({ companyName, cocNumber, tpNumber }, { setErrors }) => {
    try {
      loader.show();
      const { globalAdministrationId, identityToken } = await startFlow({ companyName, cocNumber, tpNumber });
      const postVPCRedirectUrl = new URL('/import/completeflow', window.location.href).toString();
      const vpcUrl = visionplannerDetails.visionplannerUrl + encodeUrlTag`/external/administration/${globalAdministrationId}/financial-import-source/aahg?redirectUrl=${postVPCRedirectUrl}`;
      loginWithIdToken({
        authorizationServerUrl: visionplannerDetails.authorizationServerUrl,
        idToken: identityToken,
        redirectUrl: vpcUrl,
      });
    } catch (error) {
      setErrors({ submitError: error.message || 'Er is een onverwachte fout opgetreden.' });
    } finally {
      loader.hide();
    }
  };
  
  return (
    <Layout title="Boekhoudkoppeling instellen">
      <p>
        Vul hieronder uw bedrijfsnaam, uw KVK-nummer en het TP-nummer van uw intermediair in. Kies "Volgende stap" om uw boekhoudpakket te selecteren.
      </p>
      <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit} component={OnboardingForm} />
    </Layout>
  );
};

export default Onboarding;
